import * as React from 'react';
import {
    ReferenceInput,
    SelectInput,
    Edit,
    TextInput,
    PasswordInput,
    Toolbar,
    FormWithRedirect,
    required,
    email,
    minLength,
    SaveButton,
    DeleteWithConfirmButton,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import Aside from './aside';
import { validatePasswords } from './create';
import { AvatarInput } from './AvatarInput';
import medio from "Static/data/medio.json";
import { useTranslate } from "ra-core";

export const JournalistEdit = props => (
    <Edit
        title=" "
        aside={!medio?.only_club ? <Aside /> : null}
        {...props}
        component="div">
        <JournalistForm {...props} />
    </Edit>
);

const JournalistForm = ({ permissions, ...props }) => {
    const translate = useTranslate();
    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate("components.edit_user")} ({formProps.record && formProps.record.id})
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1} style={{ alignSelf: "center" }}>
                                            <AvatarInput resource="journalists" fullWidth record={formProps.record} />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="first_name"
                                                resource="journalists"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="last_name"
                                                resource="journalists"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1}>
                                            <TextInput
                                                type="email"
                                                source="email"
                                                resource="journalists"
                                                validate={[email(), required()]}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box flex={1}></Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <TextInput
                                            source="dni"
                                            resource="journalists"
                                        />
                                    </Box>
                                    {permissions && permissions.some(permission => permission.name === '*' || permission.name === 'change-role-to-journalist') &&
                                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                            <ReferenceInput label={translate("components.profile")} source="roles" reference="roles">
                                                <SelectInput optionText="name" />
                                            </ReferenceInput >
                                        </Box>
                                    }

                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        {translate("components.change_password")}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                resource="journalists"
                                                validate={[minLength(6)]}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password_confirmation"
                                                resource="journalists"
                                                validate={[minLength(6)]}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>

                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            style={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <SaveButton 
                                handleSubmitWithRedirect={formProps.handleSubmit}
                                record={formProps.record}
                                basePath={formProps.basePath}
                                undoable={true}
                                pristine={formProps.pristine}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving}
                                resource="journalists" />
                            <DeleteWithConfirmButton 
                                basePath={formProps.basePath}
                                record={formProps.record}
                                resource="journalists" />
                        </Toolbar>
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];